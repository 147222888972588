<template>
  <div class="invoice-list">
    <CommonList
      ref="commonList"
      bottomBtnText="导出"
      :showTop="false"
      :cellOperabled="false"
      :showBottom="true"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import CommonList from "@components/CommonList.vue";

export default {
  name: "export",

  components: {
    CommonList,
  },

  methods: {
    onSubmit(data) {
      let files = [];
      files = data.map((item) => {
        return {
          id: item.id,
          fileName: item.file.fileName + "." + item.file.extension,
        };
      });
      console.log(files);
      let url = "/pages/download/index?files=" + JSON.stringify(files);
      this.$hrefTo(url);
    },
  },
};
</script>

<style lang="less" scoped></style>
